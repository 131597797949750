import { Button, Popover, Form, Row, Col, notification } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { MoreVertical, UserPlus, UserX } from 'react-feather';
import { LIGHT_THEME } from '../../../../../config/theme';
import FormUploads from '../../../../../utils/FormUploads';
import StatusTag from '../../../../../utils/StatusTag';
import { DocumentPageType, DocumentType, DocumentNameAndType } from '../types';
import add_icon from '../../../../../assets/icons/Svg/add-row.svg';
import SelectIdDocType from '../../../../../utils/FormSelects/IdDocTypesDocs';
import { authenticatedRequest } from '../../../../../api';
import { delete_director } from '../../../../../apis/business-accounts';
import { Trash2 } from 'react-feather';
import { FormInstance } from 'antd/es';

export enum DocType {
    NATIONAL_ID = 'National Id',
    PASSPORT = 'Passport Id',
}

interface Props {
    director: any;
    index: number;
    errorObj: any[];
    resendInvite: (id, name?, index?) => void;
    cancelInvite: (id, index, name?) => void;
    setInviteData: (val: any) => void;
    setDirectorModal: (val: boolean) => void;
    setDirectors: (val: any[]) => void;
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType,
        pageType: DocumentPageType,
        fileId: string,
        documentLabel?: string,
        docType?: string,
    ) => void;
    directors: any[];
    field: any;
    deleteDirector: (director, index) => void;
    form?: FormInstance;
    businessType: string;
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType,
        documentLabel?: string,
    ) => void;
    documentErrObj: any;
    disabled?: boolean;
}

const SingleDirector: FunctionComponent<Props> = ({
    director,
    index,
    errorObj,
    resendInvite,
    cancelInvite,
    setInviteData,
    setDirectorModal,
    setDirectors,
    uploadFilesToBusinessAccount,
    directors,
    field,
    deleteDirector,
    form,
    businessType,
    handleErrorFromDocsManager,
    documentErrObj,
    disabled,
}: Props) => {
    const [docType, setDocType] = useState<DocType>(director?.docType || DocType.NATIONAL_ID);
    const [visible, setVisible] = useState<boolean>(false);
    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };
    const getErr = (index, pageType) => {
        let err = undefined;
        try {
            err =
                errorObj[index]?.error ||
                documentErrObj?.[director?.name]?.[`${pageType ?? DocumentPageType.STANDARD}Error`] ||
                director?.[`reasonForReturn_${pageType ?? DocumentPageType.STANDARD}`];
        } catch (errr) {
            console.log(errr);
        }
        return err;
    };

    const isAnyDocUploaded =
        director?.[DocumentPageType.FRONT] ||
        director?.[DocumentPageType.BACK] ||
        director?.[DocumentPageType.STANDARD] ||
        director?.documentId;

    const changeDocType = (type: DocType) => {
        setDocType(type);
        if (director?.documentId) {
            authenticatedRequest()
                .delete(delete_director(director?.documentId))
                .then((res) => res.data)
                .then(() => {
                    const tempArr = [...directors];
                    tempArr[index] = {
                        ...tempArr[index],
                        [DocumentPageType.FRONT]: undefined,
                        [DocumentPageType.BACK]: undefined,
                        [DocumentPageType.STANDARD]: undefined,
                        docType: type,
                        documentId: undefined,
                    };
                    setDirectors([...tempArr]);
                })
                .catch((err) => {
                    catchErr(err);
                });
        } else {
            const tempArr = [...directors];
            tempArr[index] = {
                ...tempArr[index],
                docType: type,
            };
            setDirectors([...tempArr]);
        }
    };
    return (
        <div style={{ position: 'relative', marginTop: 10 }}>
            {director?.inviteId ? (
                <div className="director-wrapper" key={index}>
                    <div className="details-wrapper">
                        <div className="name">{director?.name}</div>
                        <div className="email">{director?.email}</div>
                    </div>
                    <StatusTag
                        value={director?.status || 'Pending'}
                        color={director?.status == 'Completed' ? LIGHT_THEME.SUCCESS : LIGHT_THEME.APPROVED}
                    />
                    <div className="more-menu">
                        <Popover
                            trigger="click"
                            visible={visible}
                            onVisibleChange={setVisible}
                            content={
                                <div className="resend-buttons-wrapper">
                                    <div
                                        className="resend-invite-wrapper"
                                        onClick={() => {
                                            setVisible(false);
                                            resendInvite(director?.inviteId, director?.firstName, index);
                                        }}
                                    >
                                        <UserPlus className="icon" />
                                        <span>Resend Invite</span>
                                    </div>
                                    {director?.status != 'Completed' && (
                                        <div
                                            className="cancel-invite-wrapper"
                                            onClick={() => {
                                                setVisible(false);
                                                cancelInvite(director?.inviteId, index, director?.firstName);
                                            }}
                                        >
                                            <UserX className="icon" />
                                            <span>Cancel Invite</span>
                                        </div>
                                    )}
                                </div>
                            }
                            placement="bottomRight"
                        >
                            <MoreVertical className="more-menu-icon" />
                        </Popover>
                    </div>
                </div>
            ) : (
                <div className="heading-wrapper">
                    <div className="main-label">{director?.['name'] || 'Director ' + (index + 1)}</div>
                    {!isAnyDocUploaded && (
                        <Button
                            className="add-button"
                            onClick={() => {
                                setInviteData({ ...director, index });
                                setDirectorModal(true);
                            }}
                            disabled={disabled}
                        >
                            <img src={add_icon} alt="add" className="plus-circle" />
                            <span className="add-tier">
                                Invite{' '}
                                {businessType == 'Company'
                                    ? 'Director'
                                    : businessType == 'Partnership'
                                    ? 'Partner'
                                    : director?.['name'] || 'Director ' + (index + 1)}
                            </span>
                        </Button>
                    )}
                    <Trash2
                        className="delete-icon"
                        style={{ marginLeft: isAnyDocUploaded ? 'auto' : 20 }}
                        onClick={() => deleteDirector(director, index)}
                    />
                </div>
            )}
            {(!director?.inviteId || director?.status == 'Completed') && (
                <>
                    <Form.Item name={[field?.fieldKey, 'documentLabel']} initialValue={director?.['name']} hidden />
                    <Row gutter={20} style={{ width: '100%' }}>
                        <Col span={12}>
                            <SelectIdDocType
                                formItemLabel="Select ID Doc Type"
                                formItemName={[field?.name, 'docType']}
                                required
                                onChange={(value) => {
                                    changeDocType(value);
                                }}
                                message="Please select document type"
                                initialValue={docType}
                                value={docType}
                                form={form}
                                disabled={disabled || director?.status == 'Completed'}
                            />
                        </Col>
                    </Row>
                    {docType == DocType.NATIONAL_ID ? (
                        <Row gutter={20}>
                            <Col span={12}>
                                <FormUploads
                                    key={docType}
                                    label={'Upload ID Document -Front of the ID'}
                                    formItemName={[field?.name, 'front']}
                                    documentConfig={
                                        director?.['documentId']
                                            ? {
                                                  documentId: director?.['documentId'],
                                                  documentName: DocumentNameAndType.DIRECTOR_ID,
                                                  documentType: DocumentType.KYC,
                                                  pageType: DocumentPageType.FRONT,
                                              }
                                            : {
                                                  documentName: DocumentNameAndType.DIRECTOR_ID,
                                                  documentType: DocumentType.KYC,
                                                  pageType: DocumentPageType.FRONT,
                                              }
                                    }
                                    getResponse={(response) => {
                                        if (response?.documentId) {
                                            const tempDirectors = [...directors];
                                            tempDirectors[index]['documentId'] = response?.documentId;
                                            tempDirectors[index][`reasonForReturn_${DocumentPageType.FRONT}`] =
                                                undefined;
                                            setDirectors([...tempDirectors]);
                                            uploadFilesToBusinessAccount(
                                                response?.documentId,
                                                response?.downloadURl,
                                                DocumentNameAndType.DIRECTOR_ID,
                                                DocumentPageType.FRONT,
                                                response?.fileId,
                                                director?.['name'],
                                                DocType.NATIONAL_ID,
                                            );
                                        } else {
                                            handleErrorFromDocsManager(
                                                response,
                                                DocumentNameAndType.DIRECTOR_ID,
                                                DocumentPageType.FRONT,
                                                director?.['name'],
                                            );
                                        }
                                    }}
                                    initialValue={director?.[DocumentPageType.FRONT]}
                                    getFileStatus={(status: boolean) => {
                                        const tempDirectors = [...directors];
                                        if (tempDirectors[index]) {
                                            tempDirectors[index]['isUploadingFront'] = status;
                                            setDirectors([...tempDirectors]);
                                        }
                                    }}
                                    disabled={disabled || director?.['isUploadingBack'] || false}
                                    err={getErr(index, DocumentPageType.FRONT)}
                                    validateStatus={getErr(index, DocumentPageType.FRONT) ? 'error' : 'success'}
                                    height="130px"
                                    showImage
                                    readOnly={director?.status == 'Completed'}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <FormUploads
                                    key={docType}
                                    label={'Back of the ID'}
                                    formItemName={[field?.name, 'back']}
                                    documentConfig={
                                        director?.['documentId']
                                            ? {
                                                  documentId: director?.['documentId'],
                                                  documentName: DocumentNameAndType.DIRECTOR_ID,
                                                  documentType: DocumentType.KYC,
                                                  pageType: DocumentPageType.BACK,
                                              }
                                            : {
                                                  documentName: DocumentNameAndType.DIRECTOR_ID,
                                                  documentType: DocumentType.KYC,
                                                  pageType: DocumentPageType.BACK,
                                              }
                                    }
                                    getResponse={(response) => {
                                        if (response?.documentId) {
                                            const tempDirectors = [...directors];
                                            tempDirectors[index]['documentId'] = response?.documentId;
                                            tempDirectors[index][`reasonForReturn_${DocumentPageType.BACK}`] =
                                                undefined;
                                            setDirectors([...tempDirectors]);
                                            uploadFilesToBusinessAccount(
                                                response?.documentId,
                                                response?.downloadURl,
                                                DocumentNameAndType.DIRECTOR_ID,
                                                DocumentPageType.BACK,
                                                response?.fileId,
                                                director?.['name'],
                                                DocType.NATIONAL_ID,
                                            );
                                        } else {
                                            handleErrorFromDocsManager(
                                                response,
                                                DocumentNameAndType.DIRECTOR_ID,
                                                DocumentPageType.BACK,
                                                director?.['name'],
                                            );
                                        }
                                    }}
                                    initialValue={director?.[DocumentPageType.BACK]}
                                    getFileStatus={(status: boolean) => {
                                        const tempDirectors = [...directors];
                                        if (tempDirectors[index]) {
                                            tempDirectors[index]['isUploadingBack'] = status;
                                            setDirectors([...tempDirectors]);
                                        }
                                    }}
                                    disabled={disabled || director?.['isUploadingFront'] || false}
                                    err={getErr(index, DocumentPageType.BACK)}
                                    validateStatus={getErr(index, DocumentPageType.BACK) ? 'error' : 'success'}
                                    height="130px"
                                    showImage
                                    readOnly={director?.status == 'Completed'}
                                    required
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Row gutter={20}>
                            <Col span={24}>
                                <FormUploads
                                    key={docType}
                                    label={'Upload ID Document'}
                                    formItemName={[field?.name, DocumentPageType.STANDARD]}
                                    documentConfig={{
                                        documentName: DocumentNameAndType.PASSPORT,
                                        documentType: DocumentType.KYC,
                                        pageType: DocumentPageType.STANDARD,
                                    }}
                                    getResponse={(response) => {
                                        if (response?.documentId) {
                                            const tempDirectors = [...directors];
                                            tempDirectors[index]['documentId'] = response?.documentId;
                                            tempDirectors[index][`reasonForReturn_${DocumentPageType.STANDARD}`] =
                                                undefined;
                                            setDirectors([...tempDirectors]);
                                            uploadFilesToBusinessAccount(
                                                response?.documentId,
                                                response?.downloadURl,
                                                DocumentNameAndType.PASSPORT,
                                                DocumentPageType.STANDARD,
                                                response?.fileId,
                                                director?.['name'],
                                            );
                                        } else {
                                            handleErrorFromDocsManager(
                                                response,
                                                DocumentNameAndType.DIRECTOR_ID,
                                                DocumentPageType.STANDARD,
                                                director?.['name'],
                                            );
                                        }
                                    }}
                                    initialValue={director?.[DocumentPageType.STANDARD]}
                                    err={getErr(index, DocumentPageType.STANDARD)}
                                    validateStatus={getErr(index, DocumentPageType.STANDARD) ? 'error' : 'success'}
                                    height="130px"
                                    showImage
                                    readOnly={director?.status == 'Completed'}
                                    required
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};
export default SingleDirector;
