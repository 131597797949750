import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import style from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { goToRoute, ROUTES } from '../../../../../router/urls';
import BusinessDropdown from '../business-dropdown';
import { preLoadComponent } from '../../../../../router/components';
import { ChevronDown } from 'react-feather';
import { SideBarItems, getSideBarLocksProperty } from './data';
import { getUser, Users } from '../../../../../config';
import BankDropDown from '../bank-dropdown';
import { RiLockPasswordFill } from 'react-icons/ri';
import { replaceUrlParams } from '../../../../../utils/Random';
const SideBarWrapper = styled.div`
    ${style}
`;

const { SubMenu } = Menu;

interface Props {
    businessName: string;
    collapsed: boolean;
    hideProfileIcon?: boolean;
    showNotification?: boolean;
    notificationSeen?: boolean;
    logout?: () => void;
}

const SideBar = ({ businessName, collapsed, hideProfileIcon, showNotification, notificationSeen, logout }: Props) => {
    const businessAccount: any = useSelector((state: RootState) => state.businessAccount);

    const auth: any = useSelector((state: RootState) => state.auth);
    const authenticatedLayoutState = useSelector((state: RootState) => state.authenticatedLayoutReducer);

    const { businessId }: any = useParams();
    const activePathIndex = businessId ? 2 : 1;
    const subPathIndex = businessId ? 3 : 2;
    const location = useLocation();
    const path = location.pathname.split('/');
    const [activePath, setActive] = useState<string>(path[activePathIndex] ? path[activePathIndex] : '');
    const [subPath, setSub] = useState<string>(path[subPathIndex] ? path[subPathIndex] : '');
    const [openKeys, setOpenKeys] = useState<string[]>(activePath ? [activePath] : []);
    const [showDropdown, setShowDropDown] = useState(false);

    const email = auth?.user?.email;
    const menuType = authenticatedLayoutState.menuType;
    const user = getUser();
    const isBank = user == Users.BANK;
    const disabled = isBank && businessAccount?.business?.businessName === 'Admin';
    const history = useHistory();
    // const hasSetUpPin = auth?.user?.hasSetupUSSDPin ?? true;
    const hasSetUpPin = true;
    const isAdmin = businessAccount?.business?.businessName === 'Admin';

    const handleOpen = (key: string, route: string) => {
        if (openKeys.indexOf(key) > -1) {
            setOpenKeys([]);
        } else {
            setOpenKeys([key]);
            if (route == ROUTES.VIEW_ALL_REGISTER_BILLS && isBank && businessAccount?.business?.id) {
                history.push(goToRoute(ROUTES.TRADE_FINANCE, businessAccount?.business?.id));
                return;
            }
            history.push(goToRoute(route, businessAccount?.business?.id));
        }
    };

    const showProfileDropDown = () => {
        setShowDropDown(true);
    };

    const closeDropdown = () => {
        setShowDropDown(false);
    };

    useEffect(() => {
        const path = location.pathname.split('/');
        path[activePathIndex] && setActive(path[activePathIndex]);
        if (path[subPathIndex]) {
            setSub(path[subPathIndex]);
        } else {
            setSub('');
        }
    }, [location]);

    useEffect(() => {
        setOpenKeys([activePath]);
    }, [activePath]);
    // const percentage = businessAccount?.profilePercentage || 0;
    const percentage = 100;

    return (
        <SideBarWrapper collapsed={collapsed}>
            <Menu
                className="gapstack-menu"
                style={{ width: '100%', paddingBottom: 0 }}
                openKeys={openKeys}
                selectedKeys={subPath === '' ? [activePath] : [subPath]}
                mode="inline"
                // inlineCollapsed={collapsed} // This causes rendering warning
                // Warning: [antd: Menu] `inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.
            >
                {hideProfileIcon ? null : (
                    <div className="account-container">
                        <Dropdown
                            overlay={
                                isBank ? (
                                    <BankDropDown showDropDown={showDropdown} closeDropDown={setShowDropDown} />
                                ) : (
                                    <BusinessDropdown
                                        showDropDown={showDropdown}
                                        closeDropdown={closeDropdown}
                                        businessId={businessId}
                                        email={email}
                                    />
                                )
                            }
                            placement="bottomLeft"
                            visible={showDropdown}
                            trigger={['click']}
                            // onVisibleChange={(visible) => setShowDropDown(visible)}
                        >
                            <div className="account-button" onClick={showProfileDropDown}>
                                <div className="account-button-content-container">
                                    <div className="profile-icon" />
                                    <span className="business-name">{businessName}</span>
                                    <ChevronDown color="#6C7392" size="20" />
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                )}

                {(percentage !== 100 || percentage < 100) && businessAccount?.business?.status != 'ON-HOLD' ? (
                    <Menu.Item className="menu-name side-action">
                        <Link to={goToRoute(ROUTES.COMPLETE_KYC, businessAccount?.business?.id)} />
                        {collapsed ? 'CR' : 'Complete Registration'}
                        <div className="side-action-dot" />
                    </Menu.Item>
                ) : !hasSetUpPin ? (
                    <Menu.Item className="menu-name side-action">
                        <Link
                            to={
                                replaceUrlParams(ROUTES.SET_UP_USSD_PIN, [
                                    { param: ':id', value: businessAccount?.business?.id },
                                ]) + `?phoneNumber=${auth?.user?.businessNumber}`
                            }
                        />
                        {collapsed ? 'CP' : 'Confirm Phone Number'}
                        <div className="side-action-dot" />
                    </Menu.Item>
                ) : null}
                {businessAccount?.business?.status == 'ON-HOLD' && (
                    <Menu.Item className="menu-name side-action">
                        <Link to={goToRoute(ROUTES.COMPLETE_KYC, businessAccount?.business?.id)} />
                        {collapsed ? 'UD' : 'Upload Documents'}
                        <div className="side-action-dot" />
                    </Menu.Item>
                )}
                {showNotification ? (
                    <Menu.Item className="menu-name side-action">
                        <Link to={goToRoute(ROUTES.NOTIFICATIONS, businessAccount?.business?.id)} />
                        Notifications
                        {!notificationSeen && <div className="side-action-dot" />}
                    </Menu.Item>
                ) : null}
                {SideBarItems(user, isAdmin).map((section, index) => {
                    if (section.menuType && menuType != section.menuType) {
                        return null;
                    }
                    return (
                        <React.Fragment key={index}>
                            {section.name && (getSideBarLocksProperty() || section.enabled) && (
                                <div className={`title-container`}>
                                    <p
                                        className={`title ${
                                            !section.enabled || (section.canDisable && disabled) ? 'disabled' : ''
                                        }`}
                                    >
                                        {section.name}
                                    </p>
                                </div>
                            )}
                            {section.items.map((val, _index) => (
                                <React.Fragment key={_index}>
                                    {val.subMenu && (getSideBarLocksProperty() || val.enabled) ? (
                                        <SubMenu
                                            key={val.key}
                                            disabled={!val.enabled || (val.canDisable && disabled)}
                                            title={
                                                <span
                                                    className={`menu-name ${
                                                        val.pathNames.indexOf(activePath) > -1 ? 'active' : ''
                                                    } ${
                                                        !val.enabled || (val.canDisable && disabled) ? 'disabled' : ''
                                                    }`}
                                                >
                                                    {!val.enabled || (val.canDisable && disabled)
                                                        ? val.disabledImage
                                                        : val.pathNames.indexOf(activePath) > -1
                                                        ? val.activeImage
                                                        : val.inactiveImage}
                                                    <span>{val.title}</span>
                                                    {!val.enabled && (
                                                        <RiLockPasswordFill
                                                            style={{ marginLeft: 10 }}
                                                            className="lock-image"
                                                        />
                                                    )}
                                                </span>
                                            }
                                            onTitleClick={() => handleOpen(val.key, val.link)}
                                            onTitleMouseEnter={() => {
                                                if (val.onhover) {
                                                    preLoadComponent(val.onhover);
                                                }
                                            }}
                                        >
                                            {val.subMenuItems?.map((subItems) => (
                                                <Menu.Item
                                                    className={`menu-name ${
                                                        subItems.pathNames.indexOf(subPath) > -1 ? 'active' : ''
                                                    }`}
                                                    key={subItems.key}
                                                    disabled={!subItems.enabled || (subItems.canDisable && disabled)}
                                                >
                                                    <Link
                                                        to={goToRoute(subItems.link, businessAccount?.business?.id)}
                                                    />
                                                    {subItems.title}
                                                    {!val.enabled && (
                                                        <RiLockPasswordFill
                                                            style={{ marginLeft: 10 }}
                                                            className="lock-image"
                                                        />
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </SubMenu>
                                    ) : getSideBarLocksProperty() || val.enabled ? (
                                        <Menu.Item
                                            key={val.key}
                                            className={`menu-name ${
                                                val.pathNames.indexOf(activePath) > -1 ? 'active' : ''
                                            } ${!val.enabled || (val.canDisable && disabled) ? 'disabled' : ''}`}
                                            disabled={!val.enabled || (val.canDisable && disabled)}
                                            icon={
                                                !val.enabled || (val.canDisable && disabled)
                                                    ? val.disabledImage
                                                    : val.pathNames.indexOf(activePath) > -1
                                                    ? val.activeImage
                                                    : val.inactiveImage
                                            }
                                            onMouseOver={() => {
                                                if (val.onhover) {
                                                    preLoadComponent(val.onhover);
                                                }
                                            }}
                                        >
                                            <Link to={goToRoute(val.link, businessAccount?.business?.id)} />
                                            {val.title}
                                            {!val.enabled && (
                                                <RiLockPasswordFill style={{ marginLeft: 10 }} className="lock-image" />
                                            )}
                                        </Menu.Item>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    );
                })}
                {logout ? (
                    <Menu.Item
                        className="menu-name"
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
                        onClick={logout}
                    >
                        Log Out
                    </Menu.Item>
                ) : null}
            </Menu>
        </SideBarWrapper>
    );
};

export default SideBar;
